<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        What <span class="text-bold">element</span> could hypothetically form an unconventional ion
        with a charge of <stemble-latex :content="ionCharge" /> and the electron configuration shown
        below?
      </p>
      <p class="pl-8 mb-5">
        <stemble-latex :content="electronConfiguration" />
      </p>

      <chemical-symbol-input
        v-model="inputs.element"
        prepend-text="$\text{Element:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';

export default {
  name: 'Question211',
  components: {
    ChemicalSymbolInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        element: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    // ecVersion
    electronConfiguration() {
      if (this.versionNumber.value === 1) {
        // Mg+
        return '$\\text{1s}^2\\text{2s}^2\\text{2p}^6\\text{3s}^1$';
      } else if (this.versionNumber.value === 2) {
        // Ca+
        return '$\\text{1s}^2\\text{2s}^2\\text{2p}^6\\text{3s}^2\\text{3p}^6\\text{4s}^1$';
      } else if (this.versionNumber.value === 3) {
        // P^-
        return '$\\text{1s}^2\\text{2s}^2\\text{2p}^6\\text{3s}^2\\text{3p}^4$';
      } else if (this.versionNumber.value === 4) {
        // P^2-
        return '$\\text{1s}^2\\text{2s}^2\\text{2p}^6\\text{3s}^2\\text{3p}^5$';
      } else if (this.versionNumber.value === 5) {
        // S^-
        return '$\\text{1s}^2\\text{2s}^2\\text{2p}^6\\text{3s}^2\\text{3p}^5$';
      } else if (this.versionNumber.value === 6) {
        // As^-
        return '$\\text{1s}^2\\text{2s}^2\\text{2p}^6\\text{3s}^2\\text{3p}^6\\text{4s}^2\\text{3d}^{10}\\text{4p}^4$';
      } else if (this.versionNumber.value === 7) {
        // As^2-
        return '$\\text{1s}^2\\text{2s}^2\\text{2p}^6\\text{3s}^2\\text{3p}^6\\text{4s}^2\\text{3d}^{10}\\text{4p}^5$';
      } else if (this.versionNumber.value === 8) {
        // Se^-
        return '$\\text{1s}^2\\text{2s}^2\\text{2p}^6\\text{3s}^2\\text{3p}^6\\text{4s}^2\\text{3d}^{10}\\text{4p}^5$';
      } else {
        return '';
      }
    },
    ionCharge() {
      if (this.versionNumber.value === 1) {
        return '$\\textbf{+1}$';
      } else if (this.versionNumber.value === 2) {
        return '$\\textbf{+1}$';
      } else if (this.versionNumber.value === 3) {
        return '$\\textbf{-1}$';
      } else if (this.versionNumber.value === 4) {
        return '$\\textbf{-2}$';
      } else if (this.versionNumber.value === 5) {
        return '$\\textbf{-1}$';
      } else if (this.versionNumber.value === 6) {
        return '$\\textbf{-1}$';
      } else if (this.versionNumber.value === 7) {
        return '$\\textbf{-2}$';
      } else if (this.versionNumber.value === 8) {
        return '$\\textbf{-1}$';
      } else {
        return '';
      }
    },
  },
};
</script>
